// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';
import PropTypes from 'prop-types';

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function YouTube({ url }) {
  const regex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regex);
  const videoId = match[7].length === 11 ? match[7] : '';

  return (
    <div
      css={`
        position: relative;
        padding-bottom: 67%;
      `}
    >
      <iframe
        src={`https://www.youtube.com/embed/${videoId}`}
        title="Test"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        css={`
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: contain;
        `}
      />
    </div>
  );
}

YouTube.propTypes = {
  url: PropTypes.string.isRequired,
};
