// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';
import { bool, node, string, oneOf } from 'prop-types';

import styled, { css } from 'styled-components';

import { Loader } from '~components/multimedia/Loader';
import Icon from '~components/multimedia/Icon';

// ─────────────────────────────────────────────────────────────────────────────
// helpers
// ─────────────────────────────────────────────────────────────────────────────

const ButtonWrapper = styled.button`
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: transparent;
  text-decoration: none !important; /* reset link buttons styling */

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 4rem;

  border-radius: 0.5rem;
  cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'pointer')};

  transition: all 250ms;

  &:disabled {
    cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'not-allowed')};
    opacity: 0.5;
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    transform: translateY(-2px);
  }
  &:not(:disabled):active {
    transform: translateY(1px);
  }

  ${({ look }) => {
    if (look === 'primary') {
      return css`
        background: hsla(var(--hsl-primary), 1);
        color: var(--color-fg) !important;
        box-shadow: inset 0 0 0 2px hsla(var(--color), 1);

        &:not(:disabled):hover,
        &:not(:disabled):focus {
          background: hsla(var(--hsl-primary), 0.9);
          box-shadow: inset 0 0 0 2px hsla(var(--color), 1),
            0 0.5rem 0.5rem hsla(var(--hsl-text), 0.1);
        }

        & > ${Loader} {
          --hsl: var(--hsl-fg);
        }
      `;
    }

    if (look === 'primary-inverse') {
      return css`
        background: var(--color-fg);
        color: var(--color-primary);

        &:not(:disabled):hover,
        &:not(:disabled):focus {
          box-shadow: 0 0.5rem 0.5rem hsla(var(--hsl-text), 0.1);
        }

        & > ${Loader} {
          --hsl: var(--hsl-primary);
        }
      `;
    }

    if (look === 'primary-app') {
      return css`
        --color: var(--hsl-primary);

        background: hsla(var(--hsl-text), 0.05);
        color: hsla(var(--hsl-text), 0.9);

        &:not(:disabled):hover,
        &:not(:disabled):focus {
          background: hsla(var(--color), 0.15);
          box-shadow: inset 0 0 0 2px hsla(var(--color), 0.1);
          color: hsla(var(--color), 1);
        }

        & > ${Loader} {
          --hsl: var(--color);
        }
      `;
    }

    if (look === 'secondary') {
      return css`
        --color: var(--hsl-primary);

        box-shadow: inset 0 0 0 2px hsla(var(--color), 1);
        color: hsla(var(--color), 1);

        &:not(:disabled):hover,
        &:not(:disabled):focus {
          background: hsla(var(--color), 0.1);
          box-shadow: inset 0 0 0 2px hsla(var(--color), 1),
            0 0.5rem 0.5rem hsla(var(--hsl-text), 0.1);
        }

        & > ${Loader} {
          --hsl: var(--hsl-primary);
        }
      `;
    }

    if (look === 'secondary-inverse') {
      return css`
        box-shadow: inset 0 0 0 2px var(--color-fg);
        color: var(--color-fg);

        &:not(:disabled):hover,
        &:not(:disabled):focus {
          background: hsla(var(--hsl-fg), 0.1);
          color: var(--color-fg);
        }

        /* & > ${Loader} {
          --hsl: var(--hsl-fg);
        } */
      `;
    }

    if (look === 'tertiary') {
      return css`
        &:not(:disabled):hover,
        &:not(:disabled):focus {
          color: var(--color-primary);

          & > ${Loader} {
            --hsl: var(--hsl-primary);
          }
        }

        & > ${Loader} {
          --hsl: var(--hsl-text);
        }
      `;
    }

    if (look === 'tertiary-inverse') {
      return css`
        color: var(--color-fg);

        & > ${Loader} {
          --hsl: var(--hsl-fg);
        }
      `;
    }

    return null;
  }};
`;

const ButtonText = styled.span`
  font-weight: 700;
  text-align: center;
  line-height: 3rem;

  ${({ ellipsis }) => {
    if (ellipsis) {
      return css`
        display: inline-block;
        max-width: ${ellipsis};
        overflow-x: hidden;
        overflow-y: visible;
        text-overflow: ellipsis;
        white-space: nowrap;
      `;
    }
    return null;
  }}
`;

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

const Button = React.forwardRef(({ loading, ellipsis, icon, children, ...rest }, forwardedRef) => {
  const renderExtraLeft = () => {
    const margin = children ? 'margin: 0 1rem 0 0' : '';
    if (loading) return <Loader css={margin} />;
    if (icon) return <Icon icon={icon} css={margin} />;
    return null;
  };
  const renderChildren = () => {
    if (children) return <ButtonText ellipsis={ellipsis}>{children}</ButtonText>;
    return null;
  };

  return (
    <ButtonWrapper ref={forwardedRef} isLoading={loading} {...rest}>
      {renderExtraLeft()}
      {renderChildren()}
    </ButtonWrapper>
  );
});

Button.displayName = 'Button';
Button.propTypes = {
  type: string,
  look: oneOf([
    'primary',
    'primary-inverse',
    'primary-app',
    'secondary',
    'secondary-inverse',
    'tertiary',
    'tertiary-inverse',
  ]),
  loading: bool,
  ellipsis: string,
  icon: string,
  children: node.isRequired,
};
Button.defaultProps = {
  type: 'button',
  look: undefined,
  loading: false,
  icon: '',
  ellipsis: '20ch',
};

export default Button;
