// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import styled from 'styled-components';

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export const Blockquote = styled.blockquote`
  quotes: '“' '”' '‘' '’';

  &::before {
    content: open-quote;
  }
  &::after {
    content: close-quote;
  }
`;
