// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React, { forwardRef } from 'react';
import { func } from 'prop-types';

import { Input } from '~components/primitives/Input';

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

const TextAreaInput = forwardRef(({ onChange, onResize, ...rest }, forwardedRef) => {
  const ref = forwardedRef || React.createRef();

  const autoResize = (element) => {
    const { style } = element; // prevent no-param-reassign eslint error
    style.height = 'inherit'; // reset scrollHeight when deleting text

    const { borderTopWidth, borderBottomWidth } = window.getComputedStyle(element);
    const extraHeight = parseInt(borderTopWidth, 10) + parseInt(borderBottomWidth, 10);

    const newHeight = element.scrollHeight + extraHeight;
    style.height = `${newHeight}px`; // resize element to accommodate potential scroll
    onResize(newHeight);
  };

  React.useEffect(() => {
    autoResize(ref.current);
  }, []);

  const handleChange = (event) => {
    autoResize(event.target);
    onChange(event);
  };

  return <Input as="textarea" ref={ref} onChange={handleChange} {...rest} />;
});

TextAreaInput.displayName = 'TextAreaInput';
TextAreaInput.propTypes = {
  onChange: func,
  onResize: func,
};
TextAreaInput.defaultProps = {
  onChange: () => {},
  onResize: () => {},
};

export default TextAreaInput;
