// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import styled from 'styled-components';

// ─────────────────────────────────────────────────────────────────────────────
// h1
// ─────────────────────────────────────────────────────────────────────────────

export const H1 = styled.h1`
  font-family: Merriweather, sans-serif;
`;

// ─────────────────────────────────────────────────────────────────────────────
// h2
// ─────────────────────────────────────────────────────────────────────────────

export const H2 = styled.h2`
  font-family: Merriweather, sans-serif;
`;

// ─────────────────────────────────────────────────────────────────────────────
// h3
// ─────────────────────────────────────────────────────────────────────────────

export const H3 = styled.h3``;
