// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Header, Link, Nav, Logo, Button, Badge } from '~components';

// ─────────────────────────────────────────────────────────────────────────────
// query
// ─────────────────────────────────────────────────────────────────────────────

export const fragment = graphql`
  fragment HeaderFragment on MdxFrontmatter {
    announcement {
      title
      url
      body
    }
    links {
      title
      url
      badge
      links {
        title
        url
        badge
      }
      look
      type
    }
  }
`;

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function HeaderContainer() {
  const { header } = useStaticQuery(graphql`
    query {
      header: mdx(
        fileAbsolutePath: { regex: "/cms/menus/" }
        frontmatter: { title: { eq: "Header" } }
      ) {
        frontmatter {
          ...HeaderFragment
        }
      }
    }
  `);

  return (
    <Header
      css={`
        position: sticky;
        top: 0;
        background-color: var(--color-fg);
        z-index: var(--z-index-header);
        box-shadow: inset 0 -2px hsla(var(--hsl-text), 0.05);
        padding: 0 var(--width-outside);
      `}
    >
      {header?.frontmatter?.announcement?.map(({ url, body }) => (
        <Link
          key={url}
          to={url}
          css={`
            display: block;
            background: hsla(var(--hsl-primary), 1);
            padding: 2rem;

            font-size: 1.75rem;
            font-weight: 700;
            text-align: center;
            color: var(--color-fg);

            &::after {
              content: '›';
              display: inline-block;
              margin: 0 0 0 1rem;
              transition: transform 250ms;
            }

            &:hover {
              background: hsla(var(--hsl-primary), 0.95);

              &::after {
                transform: translateX(0.5rem);
              }
            }
          `}
        >
          {body}
        </Link>
      ))}
      <Nav>
        <Nav.Link
          to="/"
          css={`
            display: flex;
            align-items: center;
            padding-left: 0 !important;
          `}
        >
          <Logo
            css={`
              padding: 1.5rem 0;
            `}
          />
        </Nav.Link>
        <Nav.Toggle />
        <Nav.List>
          {header?.frontmatter?.links.map((item) => {
            if (item.type === 'link') {
              return (
                <Nav.List.Item key={item.url}>
                  <Nav.Link to={item.url}>
                    {item.title}
                    {item.badge && <Badge>{item.badge}</Badge>}
                  </Nav.Link>
                </Nav.List.Item>
              );
            }

            if (item.type === 'button') {
              return (
                <Nav.List.Item
                  key={item.url}
                  css={`
                    align-items: center;
                  `}
                >
                  <Button as={Nav.Link} to={item.url} look={item.look}>
                    {item.title}
                  </Button>
                </Nav.List.Item>
              );
            }

            if (item.type === 'nested') {
              return (
                <Nav.List.Item key={item.title}>
                  <Nav.Link
                    as="span"
                    css={`
                      @media screen and (max-width: 899px) {
                        opacity: 0.5;
                      }
                    `}
                  >
                    {item.title}
                  </Nav.Link>
                  <Nav.List>
                    {item.links.map((link) => (
                      <Nav.List.Item key={link.url}>
                        <Nav.Link to={link.url}>
                          {link.title}
                          {link.badge && <Badge>{link.badge}</Badge>}
                        </Nav.Link>
                      </Nav.List.Item>
                    ))}
                  </Nav.List>
                </Nav.List.Item>
              );
            }

            return null;
          })}
        </Nav.List>
      </Nav>
    </Header>
  );
}
