// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React, { useEffect } from 'react';

import { useLocalStorage, getBrowserTheme } from '~utils';

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function DarkModeContainer({ ...rest }) {
  const [theme, setTheme] = useLocalStorage('theme', getBrowserTheme());

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  return (
    <input
      type="checkbox"
      title="Toggle dark mode"
      aria-label="Toggle dark mode"
      css={`
        --size: 1.75rem;

        appearance: none;
        outline: none;
        cursor: pointer;

        width: var(--size);
        height: var(--size);
        border-radius: 999px;

        box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0;
        transition: box-shadow 500ms, transform 500ms;

        &:checked {
          --ray-size: calc(var(--size) * -0.4);
          --offset-orthogonal: calc(var(--size) * 0.65);
          --offset-diagonal: calc(var(--size) * 0.45);

          transform: scale(0.75) rotate(90deg);
          box-shadow: inset 0 0 0 var(--size),
            calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size),
            var(--offset-orthogonal) 0 0 var(--ray-size),
            0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size),
            0 var(--offset-orthogonal) 0 var(--ray-size),
            calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0 var(--ray-size),
            var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size),
            calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size),
            var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size);
        }

        &:hover,
        &:focus {
          color: var(--color-primary);
        }
      `}
      checked={theme === 'dark'}
      onChange={({ target }) => setTheme(target.checked ? 'dark' : 'light')}
      {...rest}
    />
  );
}
