// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import styled from 'styled-components';

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export const Logo = styled.span`
  display: block;
  font-family: Merriweather, sans-serif;
  font-weight: 700;
`;

Logo.defaultProps = {
  children: 'Richard Asher',
};
