// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import styled from 'styled-components';

import Link from '../interactive/Link';

// ─────────────────────────────────────────────────────────────────────────────
// Nav
// ─────────────────────────────────────────────────────────────────────────────

export const Nav = styled.nav`
  position: relative;

  @media screen and (min-width: 900px) {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
`;

Nav.List = styled.ul`
  list-style: none;

  font-weight: 700;
  font-size: 1.5rem;

  @media screen and (max-width: 899px) {
    & & {
      padding-left: 1rem;
    }
  }

  @media screen and (min-width: 900px) {
    display: grid;
    grid-auto-flow: column;
    justify-self: center;

    &:last-of-type {
      justify-self: end;
    }
  }
`;

Nav.List.Item = styled.li`
  @media screen and (min-width: 900px) {
    display: flex;
    position: relative;

    & > ${Nav.List} {
      height: 0;
      overflow: hidden;
      position: absolute;
      top: 100%;
      right: 0;
      grid-auto-flow: row;
    }

    &:hover,
    &:focus-within {
      & > ${Nav.List} {
        height: auto;
        overflow: visible;
        background: var(--color-fg);
        box-shadow: 0 0.5rem 1rem hsla(var(--hsl-text), 0.1);
      }
    }
  }
`;

Nav.Link = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  font-weight: 700;
  white-space: pre;

  &:hover {
    color: var(--color-primary);
  }

  @media screen and (min-width: 900px) {
    padding: 1rem 3rem;
  }
`;

Nav.Toggle = styled.input`
  appearance: none;
  outline: none;

  position: absolute;
  right: 0;
  top: 2.5rem;
  cursor: pointer;
  font-size: 2rem;
  line-height: 1;
  color: var(--color-primary);
  transition: transform 150ms;

  &::after {
    content: '☰';
    display: block;
  }

  &:checked {
    transform: rotate(90deg);

    & ~ ${Nav.List} {
      height: auto;
    }
  }

  & ~ ${Nav.List} {
    height: 0;
    overflow: hidden;
  }

  @media screen and (min-width: 900px) {
    display: none;

    & ~ ${Nav.List} {
      height: auto;
      overflow: visible;
    }
  }
`;
Nav.Toggle.defaultProps = {
  type: 'checkbox',
};
