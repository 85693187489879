// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import { Ul, Li, Img, H3, P, Link, YouTube } from '~components';

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function ThumbnailsContainer({ thumbnails }) {
  const data = useStaticQuery(graphql`
    query {
      books: allMdx(
        filter: { fileAbsolutePath: { regex: "/cms/books/" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        nodes {
          frontmatter {
            title
            subtitle
            cover {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            url
          }
        }
      }
      journalism: allMdx(
        filter: { fileAbsolutePath: { regex: "/cms/journalism/" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        nodes {
          frontmatter {
            title
            subtitle
            cover {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            pdf {
              publicURL
            }
            url
          }
        }
      }
      voiceover: allMdx(
        filter: { fileAbsolutePath: { regex: "/cms/voiceover/" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        nodes {
          frontmatter {
            title
            subtitle
            cover {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            url
          }
        }
      }
      sports: allMdx(
        filter: { fileAbsolutePath: { regex: "/cms/sports/" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        nodes {
          frontmatter {
            title
            subtitle
            url
          }
        }
      }
      audiobooks: allMdx(
        filter: { fileAbsolutePath: { regex: "/cms/audiobooks/" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        nodes {
          frontmatter {
            title
            subtitle
            cover {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            url
          }
        }
      }
    }
  `);
  return (
    <Ul
      css={`
        grid-template-columns: repeat(auto-fit, minmax(20ch, 0.5fr));
        grid-gap: 4rem;
      `}
    >
      {thumbnails === 'books' && <BooksThumbnails thumbnails={data.books} />}
      {thumbnails === 'journalism' && <JournalismThumbnails thumbnails={data.journalism} />}
      {thumbnails === 'voiceover' && <BooksThumbnails thumbnails={data.voiceover} />}
      {thumbnails === 'sports' && <MultimediaThumbnails thumbnails={data.sports} />}
      {thumbnails === 'audiobooks' && <BooksThumbnails thumbnails={data.audiobooks} />}
    </Ul>
  );
}

ThumbnailsContainer.propTypes = {
  thumbnails: PropTypes.string.isRequired,
};

// ─────────────────────────────────────────────────────────────────────────────
// helpers
// ─────────────────────────────────────────────────────────────────────────────

function BooksThumbnails({ thumbnails }) {
  return thumbnails.nodes.map(({ frontmatter: { title, subtitle, cover, url } }) => (
    <Li key={title}>
      <Img
        {...cover?.childImageSharp?.fluid}
        imgProps={{ objectFit: 'contain', objectPosition: 'left' }}
        css="margin-bottom: 2rem;"
      />
      <H3 css="font-weight: 700;font-family: Merriweather, sans-serif;">{title}</H3>
      <P css="margin-bottom: 2rem;">{subtitle}</P>
      {url && (
        <Link to={url} look="primary">
          Get your copy
        </Link>
      )}
    </Li>
  ));
}

function JournalismThumbnails({ thumbnails }) {
  return thumbnails.nodes.map(({ frontmatter: { title, subtitle, cover, pdf, url } }) => (
    <Li key={title} css="position:relative;">
      <Img
        {...cover?.childImageSharp?.fluid}
        imgProps={{ objectFit: 'contain', objectPosition: 'left' }}
        css="margin-bottom: 2rem;"
      />
      <H3 css="font-weight: 700;font-family: Merriweather, sans-serif;">{title}</H3>
      <P css="margin-bottom: 2rem;">{subtitle}</P>
      {(pdf || url) && (
        <Link
          to={pdf?.publicURL || url}
          look="secondary"
          forceExternalLink
          css={`
            &::after {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
            }
          `}
        >
          See article
        </Link>
      )}
    </Li>
  ));
}

function MultimediaThumbnails({ thumbnails }) {
  return thumbnails.nodes.map(({ frontmatter: { title, url } }) => {
    if (!url) return null;

    if (url.includes('youtube')) {
      return (
        <Li key={title} css="grid-column: 1/-1;">
          <YouTube url={url} />
        </Li>
      );
    }

    if (url.includes('soundcloud')) {
      return (
        <Li key={title} css="grid-column: 1/-1;">
          <iframe
            title={title}
            width="100%"
            height="300"
            scrolling="no"
            frameBorder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/247837899&visual=true&hide_related=true"
          />
        </Li>
      );
    }

    return null;
  });
}
